import moment from "moment";
import { getArticleActions } from "../actions";

const initialState = {
  isLoading: false,
  isFailed: false,
  currentArticle: {}
};

const getArticle = (state = initialState, action) => {
  switch (action.type) {
    case getArticleActions.started:
      return { ...state, isLoading: true, isFailed: false, currentArticle: {} };
    case getArticleActions.complete:
      return {
        ...state,
        currentArticle: {
          ...action.result,
          postedOn: moment(action.result.postedOn)
        },
        isLoading: false,
        isFailed: false
      };
    case getArticleActions.failed:
      return {
        ...state,
        isLoading: false,
        isFailed: true
      };
    default:
      return state;
  }
};

export { getArticle };
