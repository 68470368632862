import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";

import { SmallMenu } from "../../styles";

const SmallNavigation = ({ menuItems, currentItem, onChange }) => {
  return (
    <SmallMenu vertical fluid>
      <Dropdown floating item text={currentItem.text}>
        <Dropdown.Menu>
          {menuItems.map(item => (
            <Dropdown.Item
              key={item.linkTo}
              as={Link}
              name={item.linkTo}
              to={item.linkTo}
              onClick={onChange}
            >
              {item.text}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </SmallMenu>
  );
};

export { SmallNavigation };
