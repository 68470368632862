import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Markdown from "react-markdown";
import { Link, useHistory } from "react-router-dom";
import { Loader, Button } from "semantic-ui-react";
import { Content, Header, RightAlign } from "../../shared/styles";
import { Logo } from "../../shared/components";
import { getArticle as createGetArticle } from "../actions";
import { BlogBreadcrumb } from "./BlogBreacrumb";
import { DeleteArticle } from "./DeleteArticle";
import { deleteArticle as createDeleteArticle } from "../actions";

const Article = ({ id }) => {
  const article = useSelector(state => state.getArticle);
  const user = useSelector(state => state.user.current);
  const dispatch = useDispatch();
  const getArticle = createGetArticle(dispatch);
  const history = useHistory();
  const navigateToSummaries = () => {
    history.push(`/blog`);
  };

  const [showConfirmDelete, setConfirmDelete] = useState(false);
  const deleteState = useSelector(state => state.deleteArticle);
  const onDeleteComplete = () => {
    setConfirmDelete(false);
    navigateToSummaries();
  };
  const deleteArticle = createDeleteArticle(dispatch, onDeleteComplete);

  useEffect(() => {
    async function getArticleByLink(id) {
      await getArticle(id);
    }
    getArticleByLink(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderEditControls = () => {
    if (!user.isAuthenticated) {
      return null;
    }

    return (
      <RightAlign>
        <Button
          as={Link}
          to={`/blog/${id}/edit`}
          icon="edit"
          color="green"
          content="Muokkaa"
        ></Button>
        <Button
          icon="remove circle"
          color="red"
          onClick={() => setConfirmDelete(true)}
          content="Poista"
        ></Button>
      </RightAlign>
    );
  };

  return (
    <Content text>
      <BlogBreadcrumb header={article.currentArticle.header} />
      <Header>{article.currentArticle.header}</Header>
      <Loader active={article.isLoading} inline="centered" size="huge">
        Haetaan tekstiä
      </Loader>
      <Markdown source={article.currentArticle.body}></Markdown>
      {renderEditControls()}
      <DeleteArticle
        isOpen={showConfirmDelete}
        onDelete={() => {
          deleteArticle(id);
        }}
        onCancel={() => setConfirmDelete(false)}
        isLoading={deleteState.isLoading}
        article={article.currentArticle}
      />
      <Logo />
    </Content>
  );
};

export { Article };
