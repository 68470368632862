import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Button, Icon, ItemGroup, Loader } from "semantic-ui-react";
import { Content, Header } from "../../../shared/styles";
import { Logo } from "../../../shared/components";
import { Summary } from "./Summary";
import { getSummaries as createGetSummaries } from "../../actions";

const Summaries = ({ user }) => {
  const { summaries, isLoading } = useSelector(state => state.getSummaries);
  const dispatch = useDispatch();

  const getSummaries = createGetSummaries(dispatch);
  useEffect(() => {
    async function loadSummaries() {
      await getSummaries();
    }
    loadSummaries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Content text>
      <Header textAlign="left" size="large">
        Hetki on sinun
      </Header>
      <p>
        "Mikään ei ole niin ärsyttävää, kuin jäädä housujen vyölenkistä kiinni
        ovenkahvaan” - hetki on niin aito ja täynnä tunnetta, että voin tuntea
        ”sarven” kasvavan otsaani.
      </p>
      <p>
        Kirjoitan tässä blogissa arjen hetkistä aitoina tilanteina ja siitä
        miten ne etenevät.
      </p>
      <Loader inline="centered" active={isLoading} size="huge">
        Haetaan tekstejä
      </Loader>
      {user.isAuthenticated ? (
        <Button color="blue" icon as={Link} to="/blog/new">
          <Icon name="add" />
          Lisää uusi artikkeli
        </Button>
      ) : null}
      {summaries.map(summary => (
        <ItemGroup key={summary.header}>
          <Summary summary={summary} />
        </ItemGroup>
      ))}
      <Logo />
    </Content>
  );
};

export { Summaries };
