import { api } from "../../api";
import { action } from "./action";

const deleteArticleActions = {
  started: "DELETE_ARTICLE_STARTED",
  complete: "DELETE_ARTICLE_COMPLETE",
  failed: "DELETE_ARTICLE_FAILED",
  failConfirmed: "DELETE_ARTICLE_FAIL_CONFIRMED"
};

const callApi = async id => await api.deleteArticle(id);
const deleteArticle = (dispatch, onSuccess) =>
  action(dispatch, callApi, deleteArticleActions, onSuccess);
const failConfirmed = dispatch =>
  dispatch({ type: deleteArticleActions.failConfirmed });

export { deleteArticle, failConfirmed, deleteArticleActions };
