import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Summaries } from "./summaries";
import { Article, AddArticle, UpdateArticle } from "./article";

const getArticleUrl = (path, user) => {
  const pathComponents = path.split("/");
  return {
    articleName: pathComponents[2],
    isNew: () => pathComponents[2] === "new" && user.isAuthenticated,
    isEdit: () => pathComponents[3] === "edit" && user.isAuthenticated
  };
};

const Blog = () => {
  const location = useLocation();
  const user = useSelector(state => state.user.current);
  const url = getArticleUrl(location.pathname, user);

  if (url.isNew()) {
    return <AddArticle />;
  }

  if (url.isEdit()) {
    return <UpdateArticle id={url.articleName} />;
  }

  if (!url.articleName) {
    return <Summaries user={user} />;
  }

  return <Article user={user} id={url.articleName} />;
};

export { Blog };
