import styled from "styled-components";

const SointuHuman = styled.div`
  z-index: 1;
  margin-left: -135px;
  @media only screen and (min-width: 1200px) {
    margin-top: -80px;
  }
  @media only screen and (max-width: 799px) {
    margin-left: 40px !important;
  }
  position: absolute;
  img {
    opacity: 1;
  }
`;

export { SointuHuman };
