import React from "react";
import { Content } from "../shared/styles";
import { Logo } from "../shared/components";

const Groups = () => (
  <Content text>
    <p>Sointukylpy</p>

    <p>
      Ryhmässä rentoudutaan leväten alustalla tai istuen pienellä jakkaralla
      matalien ja kirkkaiden sointujen vaeltaen tilassa. Rentoutuminen koetaan
      henkilön oman hetken tai ryhmälle valitun teeman mukaisesti.
    </p>

    <p>
      Sointukylvyssä soivat maljat ja gongit. Ryhmän muodostavat esimerkiksi
      työkaverit, ystävät tai Sointukylpyyn ilmoittautuneet yksittäiset
      henkilöt.
    </p>

    <p>
      Sointukylpy valmisteluineen kestää tunnin, josta 40 minuutin ajan
      rentoudutaan antaen maljojen ja gongin sointujen kuljettaa ryhmä
      miellyttävään rentoutumisen tilaan.
    </p>

    <p>Sointukylvyn hinta on 25 eur per henkilö, minimihinta 90 eur.</p>
    <Logo />
  </Content>
);

export { Groups };
