const initialState = {
  current: {
    isAuthenticated: false,
    name: null
  }
};

const user = (state = initialState, action) => {
  if (action.type === "USER_LOADED") {
    return { ...state, current: action.user };
  }
  return state;
};

export { user };
