import React from "react";
import { Button, Header, Icon, Modal } from "semantic-ui-react";

const DeleteArticle = ({ isOpen, isLoading, onDelete, onCancel, article }) => (
  <Modal open={isOpen} basic size="small">
    <Header
      icon="remove circle"
      content={`Oletko varma, että haluat poistaa artikkelin?`}
    />
    <Modal.Content>
      <h3>{`Poistetaanko artikkeli "${article.header}" pysyvästi?`}</h3>
    </Modal.Content>
    <Modal.Actions>
      <Button basic color="red" inverted onClick={onCancel}>
        <Icon name="remove" /> Ei
      </Button>
      <Button color="green" inverted loading={isLoading} onClick={onDelete}>
        <Icon name="checkmark" /> Kyllä
      </Button>
    </Modal.Actions>
  </Modal>
);

export { DeleteArticle };
