import styled from "styled-components";
import { Container } from "semantic-ui-react";

const Content = styled(Container)`
  position: relative;
  z-index: 2;
  text-align: left;
  text-shadow: 0 0 8px #f8f7f5;
  padding: 10px;
  min-height: 1000px;
  max-width: 90vw !important;
  background-image: linear-gradient(
    180deg,
    rgba(248, 247, 245, 1),
    rgba(248, 247, 245, 0.8),
    rgba(248, 247, 245, 0.6)
  );
`;

export { Content };
