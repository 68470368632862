import React from "react";
import { Link } from "react-router-dom";
import { LargeMenu } from "../../styles";

const LargeNavigation = ({ menuItems, currentPath, onChange }) => {
  return (
    <LargeMenu compact size="huge" color="blue">
      {menuItems.map((item, index) => (
        <LargeMenu.Item
          key={index}
          as={Link}
          name={item.linkTo}
          to={item.linkTo}
          active={currentPath === item.linkTo}
          onClick={onChange}
        >
          {item.text}
        </LargeMenu.Item>
      ))}
    </LargeMenu>
  );
};

export { LargeNavigation };
