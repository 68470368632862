import { api } from "../../api";
import { action } from "./action";

const updateArticleActions = {
  started: "UPDATE_ARTICLE_STARTED",
  complete: "UPADTE_ARTICLE_COMPLETE",
  failed: "UPDATE_ARTICLE_FAILED",
  failConfirmed: "UPDATE_ARTICLE_FAIL_CONFIRMED"
};

const callApi = async (article, id) => await api.updateArticle(article, id);
const updateArticle = (dispatch, onSuccess) =>
  action(dispatch, callApi, updateArticleActions, onSuccess);
const failConfirmed = dispatch =>
  dispatch({ type: updateArticleActions.failConfirmed });

export { updateArticle, failConfirmed, updateArticleActions };
