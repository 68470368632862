import { api } from "../../api";
import { action } from "./action";

const getArticleActions = {
  started: "GET_ARTICLE_STARTED",
  complete: "GET_ARTICLE_COMPLETE",
  failed: "GET_ARTICLE_FAILED"
};

const callApi = async id => await api.getArticle(id);
const getArticle = dispatch => action(dispatch, callApi, getArticleActions);

export { getArticle, getArticleActions };
