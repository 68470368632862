import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb } from "semantic-ui-react";

const BlogBreadcrumb = ({ header }) => {
  return (
    <Breadcrumb size={"large"}>
      <Breadcrumb.Section link as={Link} to="/blog">
        Blog
      </Breadcrumb.Section>
      <Breadcrumb.Divider icon="right chevron" />
      <Breadcrumb.Section active>{header}</Breadcrumb.Section>
    </Breadcrumb>
  );
};

export { BlogBreadcrumb };
