import React from "react";
import { Content } from "../shared/styles";
import { Logo } from "../shared/components";

const Companies = () => {
  return (
    <Content text>
      <p>Työntekijälle tueksi</p>

      <p>
        Yksilölliset ovat mietteet ja yksin on mietteittensä kanssa. Jokainen
        haluaa kuitenkin tulla kuulluksi ja kuulla. Coachingissa kuunnellaan
        tavoitteena tehdä näkyväksi mahdollisuudet. Mentoroinnissa kuunnellaan,
        keskustellaan tavoitteena vahvistua omana itsenään.
      </p>

      <p>
        Sointu4Coaching - Valmennuskeskustelu aiheesta, joka omassa hetkessä on
        ajankohtainen. Valmennuksessa tarkastellaan asiaa omista näkökulmista.
        Sointu4Coachingissa soivat maljat omaan tahtiin keskustelun edetessä.
        Maljat auttavat keskittymään ja kuuntelemaan itseään.
      </p>

      <p>
        Sointu4Mentoring - Mentorointi, jossa kokemus tuo näkökulmia
        urasuunnitelmille ja -problematiikalle. Tapaamisten alussa soivat gongit
        ja maljat, joiden tarkoitus on keskittää ajatukset tähän hetkeen ja
        tulevaisuuteen. Sointu4Mentoring sopii vahvistamaan itseään työelämässä
        tapahtuvissa muutoksissa.
      </p>

      <p>
        Sointu4Coaching ja Sointu4Mentoring tapaamiset suunnitellaan ja sovitaan
        tapauskohtaisesti.
      </p>
      <Logo />
    </Content>
  );
};

export { Companies };
