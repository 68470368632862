import { api } from "../../api";

const getUser = dispatch => async () => {
  const user = await api.whoami();
  dispatch({
    type: "USER_LOADED",
    user
  });
};

export { getUser };
