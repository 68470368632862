import styled from "styled-components";
import { Menu as SemanticMenu } from "semantic-ui-react";

const LargeMenu = styled(SemanticMenu)`
  margin-bottom: 80px !important;
  .active {
    font-weight: bold !important;
  }
`;

const SmallMenu = styled(SemanticMenu)`
  margin-left: 20px !important;
  max-width: 40% !important;
  margin-bottom: 25px !important;
`;

export { LargeMenu, SmallMenu };
