import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { addArticle as createAddArticle, confirmAddFailure } from "../actions";
import { Error } from "../../shared/components";

import { EditArticle } from "./EditArticle";
const AddArticle = () => {
  const history = useHistory();
  const navigateToSummaries = () => {
    history.push(`/blog`);
  };
  const dispatch = useDispatch();
  const addArticle = createAddArticle(dispatch, navigateToSummaries);
  const { isLoading, isFailed } = useSelector(state => state.addArticle);

  return (
    <>
      <Error
        isOpen={isFailed}
        onClose={() => {
          confirmAddFailure(dispatch);
        }}
      />
      <EditArticle
        header={"Uusi artikkeli"}
        body={""}
        onSave={addArticle}
        isSaving={isLoading}
        onCancel={navigateToSummaries}
      />
    </>
  );
};

export { AddArticle };
