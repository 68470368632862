import { api } from "../../api";
import { action } from "./action";

const getSummariesActions = {
  started: "GET_SUMMARIES_STARTED",
  complete: "GET_SUMMARIES_COMPLETE",
  failed: "GET_SUMMARIES_FAILED"
};

const callApi = async () => await api.getSummaries();
const getSummaries = dispatch => action(dispatch, callApi, getSummariesActions);

export { getSummaries, getSummariesActions };
