import styled from "styled-components";

const LogoContainer = styled.div`
  margin-top: 50px;
  @media only screen and (max-width: 600px) {
    float: left;
    margin-left: -50px !important;
  }
  @media only screen and (min-width: 601px) {
    float: right;
    margin-right: -100px !important;
  }
`;

export { LogoContainer };
