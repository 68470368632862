import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";

import React from "react";

const MDEditor = ({ body, onChange }) => {
  const editorOptions = {
    spellChecker: false,
    placeHolder: "Kirjoita tähän..."
  };

  return <SimpleMDE value={body} options={editorOptions} onChange={onChange} />;
};

export { MDEditor };
