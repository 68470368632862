import React from "react";
import { Content } from "../shared/styles";
import { Logo } from "../shared/components";

const You = () => (
  <Content text>
    <p>Äänimaljahieronta</p>

    <p>
      Oma hetki äänimaljojen kanssa on yksilöllinen, joka sopii arjen
      kevennykseksi tai merkkipäivän juhlistamiseksi. Hetken voi tarjota
      itselleen oman hyvinvoinnin ylläpitämiseen tai lahjaksi toiselle
      henkilölle, ketä haluaa hemmotella erityisellä tavalla.
    </p>

    <p>
      Äänimaljahieronnassa maljoja soitetaan keholla saaden aikaan ääniaaltoja,
      jotka jokainen tuntee yksilöllisellä tavalla niin kehon- kuin
      mielentasolla. Äänimaljahierontakerta kestää kokonaisuudessaan noin
      tunnin. Äänimaljahieronnan tavoitteena on tuottaa mielenrauhaa ja fyysistä
      rentoutumista.
    </p>

    <p>Äänimaljahieronta maksaa 45eur kerta.</p>
    <Logo />
  </Content>
);

export { You };
