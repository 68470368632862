import React from "react";
import { Logo as LogoImage } from "../assets";
import { LogoContainer } from "../styles";

const Logo = () => (
  <LogoContainer>
    <img src={LogoImage} alt="Sointu4 logo"></img>
  </LogoContainer>
);

export { Logo };
