import moment from "moment";
import { getSummariesActions } from "../actions";

const initialState = {
  isLoading: false,
  isFailed: false,
  summaries: []
};

const getSummaries = (state = initialState, action) => {
  switch (action.type) {
    case getSummariesActions.started:
      return { ...state, isLoading: true, isFailed: false, summaries: [] };
    case getSummariesActions.complete:
      return {
        ...state,
        summaries: action.result.map(summary => ({
          ...summary,
          postedOn: moment(summary.postedOn)
        })),
        isLoading: false,
        isFailed: false
      };
    case getSummariesActions.failed:
      return {
        ...state,
        isLoading: false,
        isFailed: true
      };
    default:
      return state;
  }
};

export { getSummaries };
