import React from "react";
import { Button, Header, Icon, Modal } from "semantic-ui-react";
const Error = ({ isOpen, onClose }) => {
  return (
    <Modal open={isOpen} onClose={onClose} basic size="small">
      <Header icon="frown outline" content="Tapahtui virhe" color="red" />
      <Modal.Content>
        <h3>Toimintoa suoritettaessa tapahtui virhe :(</h3>
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={onClose} inverted>
          <Icon name="checkmark" /> Ok
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export { Error };
