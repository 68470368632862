import styled from "styled-components";

const UserInfo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 40px;
  padding-top: 15px;
  width: 100%;
  height: 35px;
  background-color: rgba(0, 0, 0, 0.05);
  color: #2185d0;
  text-align: left;
  line-height: 0 !important;
`;

export { UserInfo };
