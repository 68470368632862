import React, { useEffect } from "react";
import { createStore, combineReducers } from "redux";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import moment from "moment";
import "moment/locale/fi";
import "./App.css";
import { withTheme } from "styled-components";
import { Root } from "./shared/styles";

import { Navigation, SointuHuman, UserInfo } from "./shared/components";

import { Blog } from "./blog";
import { Companies } from "./companies";
import { Contact } from "./contact";
import { Groups } from "./groups";
import { Home } from "./home";
import { World } from "./world";
import { You } from "./you";

import {
  addArticle,
  deleteArticle,
  getArticle,
  getSummaries,
  updateArticle
} from "./blog/reducers";
import { user } from "./user/reducers";
import { getUser as createGetUser } from "./user/actions";

function App() {
  moment.locale("fi");
  const reducers = combineReducers({
    getArticle,
    getSummaries,
    addArticle,
    updateArticle,
    deleteArticle,
    user
  });

  const store = createStore(reducers);
  const getUser = createGetUser(store.dispatch);

  useEffect(() => {
    async function getCurrentUser() {
      await getUser();
    }

    getCurrentUser();
  });

  return (
    <div className="App">
      <Provider store={store}>
        <Root textAlign="center">
          <Router>
            <UserInfo />
            <Navigation />
            <Grid container>
              <Grid.Row>
                <SointuHuman />
                <Switch>
                  <Route path="/maailma">
                    <World />
                  </Route>
                  <Route path="/ryhmille">
                    <Groups />
                  </Route>
                  <Route path="/sinulle">
                    <You />
                  </Route>
                  <Route path="/yhteystiedot">
                    <Contact />
                  </Route>
                  <Route path="/yrityksille">
                    <Companies />
                  </Route>
                  <Route path="/blog">
                    <Blog />
                  </Route>
                  <Route path="/">
                    <Home />
                  </Route>
                </Switch>
              </Grid.Row>
            </Grid>
          </Router>
        </Root>
      </Provider>
    </div>
  );
}

export default withTheme(App);
