import React from "react";
import { Content } from "../shared/styles";
import { Logo } from "../shared/components";

const Contact = () => (
  <Content text>
    <p>Kirjoita tai soita</p>

    <p>
      <strong>Anne Svenn</strong>
      <br />
      Master Of Business Administration
      <br />
      <i>Business Coach</i>
      <br />
      <i>Peter Hess Sound Massage Practitioner</i>
      <br />
      <i>Somantic Pilates Instructor</i>
    </p>

    <p>
      <strong>Ajanvaraus - toimin Helsingissä</strong>
      <br />
      Helpoiten varaat ajan lähettämällä sähköpostin tai soittamalla
      <br />
      anne@svenn.fi
      <br />
      +358 44 9834534
      <br />
      Löydät minut myös Instagramista nimellä
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.instagram.com/sointu4"
      >
        <i> sointu4</i>
      </a>
    </p>
    <Logo />
  </Content>
);

export { Contact };
