import styled from "styled-components";
import { Input } from "semantic-ui-react";

const EditHeader = styled(Input)`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
`;

export { EditHeader };
