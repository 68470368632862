import React from "react";
import { Content } from "../shared/styles";
import { Logo } from "../shared/components";
const World = () => (
  <Content text>
    <p>Aiheesta ja yhteistyössä</p>

    <p>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.medi-sound.fi"
      >
        Medi-Sound Ky
      </a>
      <br />
      "Medi-Sound Ky on Saksan Peter Hess instituutin edustaja ja valtuuttama
      kouluttaja ja hoitavien maljojen maahantuoja. Yritys toimii Suomessa Peter
      Hess Akatemiana (Peter Hess Academy of Finland)."(Medi-Sound Ky)
    </p>

    <p>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.peter-hess-institut.de"
      >
        Peter Hess Institut
      </a>
      <br />
      "Saksalainen Peter Hess Instituutti on jo on vuodesta 1985 alkaen
      kouluttanut äänimaljahieronnan ammattilaisia ja on alan johtavia
      koulutuskeskuksia Euroopassa."(Medi-Sound Ky)
    </p>

    <p>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="http://www.iloco.fi/fi/"
      >
        Iloco Oy
      </a>
      <br />
      "Ilocon tehtävänä on kannustaa ja haastaa ihmisiä; osaajia ja
      persoonia."”Kaikkea en tiedä mutta juuri mikään ei ole minulle vierasta.”
      (Iloco Oy) Voimauttava ja inspiroiva yhteistyökumppani.
    </p>

    <p>
      Manik Oy - Manik Consulting
      <br />
      Manik Oy on idearikas yhteistyökumppani, joka suunnittelee ja toteuttaa
      yrityksille suunnattuja palveluja.
    </p>
    <Logo />
  </Content>
);

export { World };
