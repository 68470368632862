const apiUrl = "https://api.sointu4.com/api";

const sendOptions = content => ({
  credentials: "include",
  mode: "cors",
  headers: {
    "Content-Type": "application/json"
  },
  body: JSON.stringify(content)
});

const httpGet = () => ({
  credentials: "include"
});

const httpPost = content => ({
  ...sendOptions(content),
  method: "POST"
});

const httpPut = content => ({
  ...sendOptions(content),
  method: "PUT"
});

const httpDelete = () => ({
  credentials: "include",
  mode: "cors",
  method: "DELETE"
});

const throwIfNotOkResponse = response => {
  if (!response.ok) {
    throw new Error("Non-ok response received");
  }
};

const getResponseBodyOrThrow = response => {
  throwIfNotOkResponse(response);
  return response.json();
};

const api = {
  whoami: async () => {
    const response = await fetch(`${apiUrl}/whoami`, httpGet());
    return getResponseBodyOrThrow(response);
  },
  getSummaries: async () => {
    const response = await fetch(`${apiUrl}/articles`, httpGet());
    return getResponseBodyOrThrow(response);
  },
  getArticle: async id => {
    const response = await fetch(`${apiUrl}/articles/${id}`, httpGet());
    return getResponseBodyOrThrow(response);
  },
  addArticle: async article => {
    const response = await fetch(`${apiUrl}/articles`, httpPost(article));
    throwIfNotOkResponse(response);
  },
  updateArticle: async (article, id) => {
    const response = await fetch(`${apiUrl}/articles/${id}`, httpPut(article));
    throwIfNotOkResponse(response);
  },
  deleteArticle: async id => {
    const response = await fetch(`${apiUrl}/articles/${id}`, httpDelete());
    throwIfNotOkResponse(response);
  }
};

export { api };
