const action = (dispatch, operation, actions, onSuccess) => async (
  first,
  second
) => {
  dispatch({
    type: actions.started
  });

  let result;

  try {
    result = await operation(first, second);
  } catch {
    return dispatch({
      type: actions.failed
    });
  }

  dispatch({
    type: actions.complete,
    result
  });

  if (onSuccess) {
    onSuccess();
  }
};

export { action };
