import { api } from "../../api";
import { action } from "./action";

const addArticleActions = {
  started: "ADD_ARTICLE_STARTED",
  complete: "ADD_ARTICLE_COMPLETE",
  failed: "ADD_ARTICLE_FAILED",
  failConfirmed: "ADD_ARTICLE_FAIL_CONFIRMED"
};

const callApi = async article => await api.addArticle(article);
const addArticle = (dispatch, onSuccess) =>
  action(dispatch, callApi, addArticleActions, onSuccess);
const failConfirmed = dispatch =>
  dispatch({ type: addArticleActions.failConfirmed });

export { addArticle, failConfirmed, addArticleActions };
