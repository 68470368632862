const initialState = {
  isLoading: false,
  isFailed: false,
  isComplete: false
};

const withNoResult = actions => (state = initialState, action) => {
  switch (action.type) {
    case actions.started:
      return { ...state, isLoading: true, isFailed: false, isComplete: false };
    case actions.complete:
      return {
        ...state,
        isLoading: false,
        isFailed: false,
        isComplete: true
      };
    case actions.failed:
      return {
        ...state,
        isLoading: false,
        isFailed: true,
        isComplete: false
      };
    case actions.failConfirmed:
      return {
        ...state,
        isFailed: false
      };
    default:
      return state;
  }
};

export { withNoResult };
