import React from "react";
import { Human } from "../assets";
import { SointuHuman as ImageContainer } from "../styles";

const SointuHuman = () => (
  <ImageContainer>
    <img src={Human} alt="Sointu4 human" />
  </ImageContainer>
);

export { SointuHuman };
