import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Item, Icon, Label } from "semantic-ui-react";
import { DeleteArticle } from "../../article/DeleteArticle";
import {
  deleteArticle as createDeleteArticle,
  getSummaries as createGetSummaries
} from "../../actions";

const Summary = ({ summary }) => {
  const dispatch = useDispatch();
  const getSummaries = createGetSummaries(dispatch);
  const user = useSelector(state => state.user.current);
  const [showConfirmDelete, setConfirmDelete] = useState(false);
  const deleteState = useSelector(state => state.deleteArticle);
  const onDeleteComplete = () => {
    setConfirmDelete(false);
    getSummaries();
  };
  const deleteArticle = createDeleteArticle(dispatch, onDeleteComplete);

  const renderEditControls = () => {
    if (!user.isAuthenticated) {
      return null;
    }

    return (
      <Item.Extra>
        <Label
          as={Link}
          to={`/blog/${summary.id}/edit`}
          icon="edit"
          color="green"
          content="Muokkaa"
        />
        <Label
          as={Link}
          to="#"
          onClick={() => setConfirmDelete(true)}
          icon="remove circle"
          color="red"
          content="Poista"
        />
      </Item.Extra>
    );
  };

  return (
    <div>
      <Item>
        <Item.Content>
          <Item.Header as="h3">
            <Link to={"/blog/" + summary.id}>{summary.header}</Link>
          </Item.Header>
          <Item.Meta>
            <Icon name="calendar alternate outline" />
            Julkaistu {summary.postedOn.format("L")}
          </Item.Meta>
          <Item.Description>{summary.summary}</Item.Description>
          {renderEditControls()}
        </Item.Content>
      </Item>
      <DeleteArticle
        isOpen={showConfirmDelete}
        onDelete={() => {
          deleteArticle(summary.id);
        }}
        onCancel={() => setConfirmDelete(false)}
        isLoading={deleteState.isLoading}
        article={summary}
      />
    </div>
  );
};

export { Summary };
