import React from "react";
import { useSelector } from "react-redux";
import { Icon } from "semantic-ui-react";
import { UserInfo as UserInfoContent, RightAlign } from "../styles";

const UserInfo = () => {
  const user = useSelector(state => state.user.current);
  return user.isAuthenticated ? (
    <UserInfoContent>
      <Icon name="user" />
      <span>{user.name}</span>
      <a href="https://api.sointu4.com/.auth/logout">
        <RightAlign>
          <Icon name="sign-out" />
          <span>Kirjaudu ulos</span>
        </RightAlign>
      </a>
    </UserInfoContent>
  ) : null;
};

export { UserInfo };
