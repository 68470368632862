import React from "react";
import { Content } from "../shared/styles";
import { Logo } from "../shared/components";

const Home = () => (
  <Content text>
    <p>Sointujen hetki</p>
    <p>
      ”Se mitä olen tehnyt on toteutunut unelmani ja mitä en ole vielä tehnyt
      siitä unelmoin”
    </p>
    <p>
      On usein toistuvia hetkiä, jolloin elämä tuntuu täyttyvän odotuksista,
      tottumuksista ja vaatimuksista unohtaa oman itsensä. On hetkiä, jolloin ei
      kukaan, ei mikään vaadi mitään mutta väsymys on esteenä muistaa ja helliä
      itseään - ja unelmoida.
    </p>
    <p>Pysähdy ja hetki on sinun.</p>
    <p>
      Äänimaljat eivät aseta odotuksia, eivät vaadi mitään vaan toivottavat
      tervetulleeksi omaan hetkeen. Hetken hiljaisuus, maljojen rauhallisten
      sointujen myötä luo tilan rentoutua ja kuunnella itseään – oman elämän
      tärkeintä instrumenttia.
    </p>
    <Logo />
  </Content>
);

export { Home };
