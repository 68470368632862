import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Container, Responsive } from "semantic-ui-react";

import { LargeNavigation } from "./LargeNavigation";
import { SmallNavigation } from "./SmallNavigation";

const Navigation = () => {
  const location = useLocation();
  const [currentPath, setItem] = useState(location.pathname);
  const changeMenuItem = (event, { name }) => setItem(name);

  const menuItems = [
    { linkTo: "/", text: "Etusivu" },
    { linkTo: "/sinulle", text: "Sinulle" },
    { linkTo: "/ryhmille", text: "Ryhmille" },
    { linkTo: "/yrityksille", text: "Yrityksille" },
    { linkTo: "/maailma", text: "Maailma" },
    { linkTo: "/blog", text: "Blog" },
    { linkTo: "/yhteystiedot", text: "Yhteystiedot" }
  ];

  const currentItem = path =>
    menuItems.find(item => item.linkTo === path) || menuItems[0];

  return (
    <Container>
      <Responsive
        as={LargeNavigation}
        menuItems={menuItems}
        currentPath={currentPath}
        onChange={changeMenuItem}
        minWidth={801}
      />
      <Responsive
        as={SmallNavigation}
        menuItems={menuItems}
        currentItem={currentItem(currentPath)}
        onChange={changeMenuItem}
        maxWidth={800}
      />
    </Container>
  );
};

export { Navigation };
