import React, { useState } from "react";
import { MDEditor } from "./MDEditor";
import { Button, Icon } from "semantic-ui-react";
import { Content, RightAlign } from "../../shared/styles";
import { Logo } from "../../shared/components";
import { BlogBreadcrumb } from "./BlogBreacrumb";
import { EditHeader } from "../styles";

const EditArticle = ({ header, body, onCancel, onSave, isSaving }) => {
  const [currentHeader, setHeader] = useState(header);
  const [currentBody, setBody] = useState(body);

  return (
    <Content text>
      <BlogBreadcrumb header={currentHeader} />
      <EditHeader
        label="Otsikko"
        size="large"
        value={currentHeader}
        onChange={(_, headerContent) => {
          setHeader(headerContent.value);
        }}
      ></EditHeader>
      <MDEditor body={currentBody} onChange={setBody} />
      <RightAlign>
        <Button color="red" icon onClick={onCancel}>
          <Icon name="cancel" />
          Peruuta
        </Button>
        <Button
          color="green"
          icon
          loading={isSaving}
          onClick={() => {
            onSave({ header: currentHeader, body: currentBody });
          }}
        >
          <Icon name="save" />
          Tallenna
        </Button>
      </RightAlign>
      <Logo />
    </Content>
  );
};

export { EditArticle };
