import styled from "styled-components";
import { Container } from "semantic-ui-react";

const Root = styled(Container)`
  background-color: #f8f7f5;
  height: 100%;
  z-index: -1;
`;

export { Root };
