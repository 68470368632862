import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import { Content } from "../../shared/styles";
import { Error } from "../../shared/components";
import {
  getArticle as createGetArticle,
  updateArticle as createUpdateArticle,
  confirmUpdateFailure
} from "../actions";
import { EditArticle } from "./EditArticle";

const UpdateArticle = ({ id }) => {
  const history = useHistory();
  const navigateToArticle = () => {
    history.push(`/blog/${getArticleState.currentArticle.id}`);
  };
  const navigateToBlog = () => {
    history.push(`/blog`);
  };
  const dispatch = useDispatch();
  const getArticle = createGetArticle(dispatch);
  const updateArticle = createUpdateArticle(dispatch, navigateToBlog);

  const getArticleState = useSelector(state => state.getArticle);
  const updateArticleState = useSelector(state => state.updateArticle);

  useEffect(() => {
    async function getArticleById(id) {
      await getArticle(id);
    }
    getArticleById(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (getArticleState.isLoading) {
    return (
      <Content text>
        <Loader active inline="centered" size="huge" />
      </Content>
    );
  }

  return (
    <>
      <Error
        isOpen={updateArticleState.isFailed}
        onClose={() => {
          confirmUpdateFailure(dispatch);
        }}
      />
      <EditArticle
        header={getArticleState.currentArticle.header}
        body={getArticleState.currentArticle.body}
        onSave={article =>
          updateArticle(article, getArticleState.currentArticle.id)
        }
        isSaving={updateArticleState.isLoading}
        isFailed={updateArticleState.isFailed}
        onCancel={navigateToArticle}
      />
    </>
  );
};

export { UpdateArticle };
